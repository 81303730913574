.gnav-my-account-v1 {
  .social-login.gnav {
    font-size: 13px;
    .social-login__email-opt-in {
      padding-top: 20px;
      label {
        font-family: $base-font-family;
        font-color: $color-darkest-grey;
        padding-#{$ldirection}: 10px;
      }
    }
  }
}

.social-login {
  .social-login__email-opt-in {
    padding-top: 20px;
    text-align: $ldirection;
  }
  .social-login__terms {
    text-align: $ldirection;
  }
}

#main {
  #facebook_email_opt_in {
    width: auto;
    height: auto;
  }
}

.checkout__content {
  .confirmation-panel {
    .social-login {
      padding-#{$ldirection}: 13px;
      .social-login__email-opt-in {
        padding-#{$ldirection}: 0;
      }
      h2.social-login__title {
        margin-bottom: 12px;
        font-size: 16px;
        color: $color-cl-soft-black;
        font-weight: bold;
        @media #{$medium-up} {
          font-weight: normal;
        }
      }
    }
    .submit_btn {
      margin-left: 0 !important;
    }
  }
}

#main.single {
  .social-login {
    label {
      display: inline;
      padding-#{$ldirection}: 10px;
    }
    .social-login__terms {
      padding: 0;
    }
  }
}

#signin {
  #content,
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        display: flex;
        margin-top: 20px;
        margin-#{$ldirection}: 0;
        .social-login__opt-in-checkbox {
          margin-top: 3px;
          width: 27px;
        }
        label {
          font-family: inherit;
          padding-#{$ldirection}: 10px;
        }
      }
      .social-login__terms {
        padding: 0;
        text-align: $ldirection;
      }
    }
  }
}
