.optanon-alert-box-wrapper {
  &.hide-accept-button {
    padding: 22px 30px 22px 22px;
    height: auto;
    display: inline-block;
    border: 10px solid $color-black;
    #{$rdirection}: 0;
    width: 100%;
    @media #{$cr19-medium-up} {
      width: 300px;
    }
    div,
    p {
      display: inline;
    }
    .optanon-alert-box-bottom-top {
      .optanon-alert-box-corner-close {
        position: static;
        .banner-close-button {
          position: absolute;
        }
      }
    }
    .optanon-alert-box-button-container {
      padding-#{$rdirection}: 0;
      margin-top: 10px;
      position: static;
      margin-#{$ldirection}: 0;
      .optanon-alert-box-button-middle {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        .optanon-toggle-display,
        .optanon-allow-all {
          font-family: $base-font-family-roman;
          color: $color-cl-green-2 !important;
          font-weight: normal;
          padding-#{$rdirection}: 0;
          border-bottom: none;
          @media #{$cr19-medium-up} {
            padding-#{$rdirection}: 5px;
          }
          &:before {
            display: none;
          }
          &:focus {
            outline: 0;
          }
        }
      }
      .optanon-alert-box-button {
        float: none;
      }
    }
    .optanon-alert-box-bg {
      .optanon-alert-box-body {
        margin: 0;
        padding: 0;
        .banner-content {
          font-size: 13px;
          line-height: normal;
          color: $color-black;
          font-family: $base-font-family-roman;
        }
      }
    }
  }
}
