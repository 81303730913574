#main.main {
  section.shipping-billing {
    .address-book-info {
      .column {
        margin: 10px 0;
        .address_display {
          line-height: 1.5em;
          .controls {
            .default-address {
              display: block;
            }
          }
        }
      }
    }
    .payment_book_container {
      .payment-info {
        margin: 10px 0;
        > div {
          line-height: 1.5em;
        }
      }
    }
  }
  .registration_content {
    .full-width-wrap {
      fieldset.demographic.fs {
        .form_element.birth_date_container {
          select#form--profile_preferences--field--BIRTH_YEAR {
            display: inline-block;
          }
        }
      }
      fieldset.email_promotions {
        .form_element.pc_email_promo_container {
          label {
            display: inline-block;
          }
        }
        .form_element.sms_promo_container {
          label {
            display: inline-block;
          }
        }
      }
    }
    .first-column-wrap {
      fieldset.profile.fs {
        .form_element.set-email-container-height {
          min-height: 57px;
        }
      }
    }
  }
  .module-spp-detail {
    .page-spp {
      .shades .shade-dd-menu {
        .col1 {
          margin: 0 !important;
          .duo-smoosh {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
  .account-favorites-mylists {
    .lists .list .items-5 {
      margin: 0 16px;
      width: 36%;
    }
  }
  .module-spp-detail .options {
    .spp_product_status {
      .temp_out_of_stock_msg {
        line-height: 21px;
        a.btn-email {
          display: block;
          width: 30%;
          margin: 5px 0;
        }
      }
    }
  }
  section.recommended-for-you.panel {
    .prod-shelf-row ul.products {
      width: 100%;
    }
  }
}

.extra {
  .inner {
    .field {
      p {
        margin: 0;
      }
    }
  }
}

.locations_table_panel {
  #store_results {
    table {
      td.store_name.marker {
        display: table-cell;
      }
      td {
        span.store_name {
          display: block;
        }
      }
    }
  }
}

html.ie8 {
  #search-wrapper {
    #summary {
      .search-again {
        input.search-term {
          border: 1px solid #bdbcbc;
          padding-top: 6px;
        }
        input.search-again {
          cursor: pointer;
          background: url('/sites/clinique/themes/cl_base/template_api/gnav_search_v1/icon-search.png')
            no-repeat scroll 102% center;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator-body {
    .form_element.search_alternative_text {
      margin: 3px 20px 0;
    }
    .form_element.search_container {
      input.search {
        width: 225px;
        letter-spacing: 0em;
      }
    }
    .form_element.distance_container {
      margin-left: 25px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section-store-locator {
    .store-locator-body {
      .form_element.search_alternative_text {
        margin: 11px 8px 0;
      }
      .form_element.search_container {
        input.search {
          width: 225px;
          letter-spacing: 0em;
          margin-top: 10px;
        }
      }
      .form_element.geo_container,
      .form_element.search_container {
        .button {
          width: 150px;
        }
      }
      .form_element.country_container {
        margin: 10px 8px;
      }
    }
  }
}

#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        padding-right: 160px;
        .sbHolder {
          width: 170px;
          float: left;
        }
      }
    }
  }
}

.site-logo-responsive-v1 {
  a img {
    margin: 10px auto auto;
  }
}

.back-to-top {
  bottom: 2.8em;
}

.gnav-drop-down-v1 {
  .stores-menu-v1 {
    form#store-locator .form-submit {
      font-size: 12px;
      padding: 0;
      width: 47%;
      letter-spacing: 0;
    }
  }
}

body#index {
  #main.single {
    .product {
      .qty {
        width: 23%;
      }
      .desc {
        width: 39%;
      }
      .thumb {
        width: 38%;
      }
    }
  }
}

.section-store-locator {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Obtiene una vista panorámica hacia la izquierda'],
      div[title^='Obtiene una vista panorámica hacia la derecha'],
      div[title^='Obtiene una vista panorámica hacia arriba'],
      div[title^='Obtiene una vista panorámica hacia abajo'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.past-purchases {
  .order.accordion-header {
    display: inline-block;
    .trans-id {
      width: 10%;
    }
    .order-status {
      width: 17%;
    }
    .view-details {
      width: 12%;
      float: right;
    }
  }
}

.spp_reviews {
  #BVRRSecondarySummaryContainer {
    .BVRRSecondaryRatingSummary.BVRRSecondaryRatingSummaryNoReviews {
      padding: 0 0 55px 25px;
      #BVRRRatingSummaryNoReviewsWriteImageLinkID {
        a {
          width: 250px;
        }
      }
    }
  }
}

.device-pc {
  .dd-content {
    &.active {
      //width: 556px;
      #store-locator {
        .form-text {
          padding: 0 0 0 3px;
          width: 248px;
        }
        .form-submit {
          padding-top: 1px;
        }
      }
    }
    .cart-confirm-wrapper {
      .prod {
        padding: 0 10px 0 0;
      }
      .hcenter {
        width: 315px;
      }
      .qty {
        margin-right: 15px;
      }
      .items {
        width: 45%;
      }
      .subtotal {
        width: 55%;
        padding-right: 10px;
      }
    }
  }
  .content {
    .customer-care-list {
      div.customer-care-list-item {
        float: none;
        margin: 0 auto;
        padding: 10px 2.5%;
        width: 100%;
        #customer_service_chat {
          padding-top: 10px;
          text-align: center;
          width: 600px;
        }
        .body-content {
          max-height: 120px;
        }
        #lpchatordertech {
          display: none;
        }
      }
      .customer-care-list-item.item-2 {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .diagnostics-merch-window.has-image {
    background-position: 468px;
  }
}

.diagnostics-merch-window.has-image {
  background-position: right;
}

.foundation-finder-merch-window-v1 .screenshot {
  margin: 58px 3% 65px 0;
}

#address_form_container #address-form-popover {
  fieldset.fs {
    div.form_element {
      width: 46%;
    }
    .default_bill_container,
    .default_ship_container {
      float: left;
    }
  }
}

.account-favorites-mylists .lists {
  .list.create-list {
    .create-wishlist-form .list-icon span label {
      background: transparent url(/media/images/css/sprite-favorites-list-icons.png) 0 0 no-repeat;
      cursor: pointer;
      float: left;
      height: 28px;
      margin: 0 25px 0 0;
      overflow: hidden;
      text-indent: -9999px;
    }
    .button.create-list {
      margin: 20px auto 0;
    }
  }
}

.device-pc.pc_spp_view {
  .full_sign_in {
    .row {
      .form-text {
        height: 35px;
        line-height: 35px;
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-view-options {
    h2 {
      width: 250px;
    }
  }
}

#search-wrapper.products .results .result .badge {
  .badge-inner.badge_data_1,
  .badge-inner.badge_data_30 {
    display: none;
  }
  .badge-inner.badge_data_1.new,
  .badge-inner.badge_data_30.bestseller {
    display: inline-block;
  }
}

#top_wrapper {
  .dd-content.active {
    width: auto;
    #store-locator {
      .form-submit {
        width: auto;
        padding: 0 10px;
      }
    }
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .contact_us_wrap {
    #contact_us {
      ul.category {
        li {
          width: 173px;
          p.copy {
            font-size: 23px;
            line-height: 20px;
          }
        }
      }
      .question_header.online_order {
        .caret {
          width: 170px;
        }
      }
      .question_header.beauty_product {
        padding-left: 155px;
      }
      .question_header.website_tech {
        padding-left: 340px;
      }
      .question_header.store_service {
        padding-left: 500px;
        .caret {
          width: 280px;
        }
      }
      #contact-form-wrap {
        .first-column-wrap {
          width: 40%;
        }
        .second-column-wrap {
          width: 60%;
        }
      }
    }
  }
}

.module-spp-detail {
  .page-spp {
    .container {
      .col1 {
        .badge {
          &.badge_28 {
            background: #fe6cb5;
          }
        }
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    ul.view-small {
      li.mpp-box {
        .badge {
          &.badge_28 {
            background: #fe6cb5;
          }
        }
      }
    }
  }
}

.section-products {
  .cl-mpp-product-layout-v1 {
    .mpp-view-options {
      margin-bottom: 10px;
    }
  }
}

#main.single {
  #payment-panel {
    .payment-option {
      .payment-container {
        padding-#{$ldirection}: 30px;
        .related-media.cc {
          width: 90%;
        }
      }
    }
  }
}

.front {
  .email-signup-block {
    &__body {
      @media #{$cr19-large-up} {
        float: #{$rdirection};
      }
      .email-signup-block {
        &__container {
          width: auto;
        }
      }
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color-light-grey;
    clear: both;
    padding: 10px;
    width: 300px;
    overflow: auto;
    li {
      padding-bottom: 1em;
    }
  }
}

.elc-size-picker-wrapper,
.elc-product-shade-picker-dropdown {
  .elc-dropdown {
    &-readonly-input {
      width: 100%;
    }
    &-arrow {
      z-index: -1;
    }
  }
}

.mantle-media-asset {
  img,
  video {
    height: auto;
  }
}
