$color-tier1: #cddbc3;
$color-tier2: #ae9dc8;
$color-tier3: #f5d16f;

#sms_promotion_container + * {
  clear: both;
}

.label_error {
  color: $color-red;
}

.checkout__offer-code__wallet-wrapper {
  .slick-track {
    .not-meet {
      opacity: 0.5;
    }
  }
}

body.loyalty_offer_signup {
  #colorbox.loyalty-popup-custom-alignment.horizontal-align--right {
    left: 0 !important;
    margin: 0 auto;
    #cboxWrapper {
      #cboxContent {
        width: 575px !important;
        @include breakpoint($ab-small-down) {
          height: 530px !important;
          width: 92% !important;
        }
        .loyalty_popover__terms {
          display: none;
        }
      }
    }
  }
}

.account-loyalty {
  .section-header {
    .smart-rewards-logo {
      margin-left: 0px;
    }
  }
  .section-content {
    .tier-point-msg {
      &.next-tier-points-msg {
        @include swap_direction(margin, 13px 10px 0 0);
        width: 50%;
        float: #{$rdirection};
      }
    }
  }
}

.form-item.loyalty {
  .loyalty_terms_condition {
    label {
      font-family: HelveticaNeueLTStd45Light, Arial, Sans-Serif;
    }
  }
}

#loyalty-status__content {
  #loyalty__page__status {
    #loyalty__panel__offers__sku {
      li.offer_not_met {
        .loyalty__panel__offers__sku__cta {
          a.not_met {
            background-color: $color-btn-gray;
          }
        }
        .loyalty__panel__offers__sku__product__image {
          opacity: 0.5;
        }
      }
      .loyalty__panel__offers__sku__remove {
        text-transform: uppercase;
      }
    }
  }
}

#loyalty__panel__offers__sku {
  .loyalty__panel__offers__error {
    li.error {
      color: $color-notice-pink;
    }
  }
}

.profile-loyalty-bonus {
  font-size: 14.5px;
  font-family: $base-font-family;
}

/* Checkout page */
.offer-section {
  .offer-code-panel {
    .checkout__panel__title {
      @media #{$medium-up} {
        font-size: 22px;
        padding-bottom: 16px;
        cursor: pointer;
      }
    }
  }
}

.checkout__offer-code__wallet {
  visibility: hidden; //hide pre slick carousel
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
  @media #{$medium-up} {
    height: 430px;
    margin-bottom: 30px;
  }
  &.offer_applied {
    &.slick-slider {
      .checkout__offer-code__wallet-item {
        &.automatic {
          .checkout__offer-code__wallet-apply {
            display: inline-block;
            border: none;
            background: $color-black;
            color: $color-white;
            line-height: 1em;
            height: auto;
          }
        }
        &.is-applied {
          .checkout__offer-code__wallet-apply {
            display: none;
          }
        }
      }
    }
    .checkout__offer-code__wallet-item {
      &.manual {
        &:not(.is-applied) {
          opacity: 0.5;
        }
      }
      .checkout__offer-code__wallet-apply {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        color: $color-white;
      }
    }
  }
  .slick-dots {
    display: none !important;
    @media #{$medium-up} {
      display: block;
      top: 200px;
      bottom: auto;
    }
  }
  .slick-prev {
    background-color: rgba(0, 0, 0, 0);
    background-image: url(/media/export/cms/nav-arrows.png);
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    background-position: left 10px;
    left: 10px;
    z-index: 100;
    @media #{$medium-up} {
      #{$ldirection}: 5px;
    }
  }
  .slick-next {
    background-color: rgba(0, 0, 0, 0);
    background-image: url(/media/export/cms/nav-arrows.png);
    background-repeat: no-repeat;
    height: 55px;
    margin: -27px 0 0 0;
    opacity: 0.3;
    position: absolute;
    text-indent: -9999px;
    top: 35%;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    width: 27px;
    background-position: right 10px;
    right: 10px;
    text-align: left;
    z-index: 100;
    @media #{$medium-up} {
      #{$rdirection}: 5px;
    }
  }
  .checkout__offer-code__wallet {
    &-item {
      text-align: center;
      font-size: 21px;
      @media #{$medium-up} {
        font-size: 22px;
      }
      &__image-container {
        padding: 0;
        margin-bottom: 16px;
        @media #{$medium-up} {
          margin-bottom: 35px;
        }
      }
      &__inner {
        float: none;
        width: auto;
        padding: 0;
      }
      &__image {
        display: none;
        width: 35%;
        background: $color-white;
        @media #{$medium-up} {
          width: 250px;
          height: 250px;
          background: $color-white;
        }
        @include breakpoint($ab-small-down) {
          width: 60%;
        }
        &__title {
          color: $color-black;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px;
          line-height: 1.1;
          letter-spacing: 0.02em;
        }
      }
      &__image_clinique {
        display: inline-block !important;
      }
      &__header {
        font-size: 21px;
        @media #{$medium-up} {
          font-size: 22px;
        }
      }
      &__description {
        font-size: 15px;
        line-height: 1.6;
        @media #{$medium-up} {
          font-size: 16px;
          line-height: 2;
        }
      }
      &__button-container {
        position: relative;
        margin-top: 20px;
        @media #{$medium-up} {
          text-align: center;
        }
      }
      &.is-applied {
        .checkout__offer-code__wallet {
          &-apply {
            display: none;
          }
          &-remove {
            display: inline-block;
          }
          &-selected {
            display: inline-block;
          }
          &-item__promo-applied {
            display: block;
            margin-top: 20px;
            font-size: 12px;
          }
        }
      }
      &__button-loader {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(/media/images/account/loyalty/ajax-loader.gif) no-repeat center center
          $color-white;
        z-index: 10;
        @media #{$medium-up} {
          background-color: $color-white;
        }
      }
      &__promo-applied {
        display: none;
      }
      &__link-container {
        margin-top: 20px;
      }
      &__link {
        font-size: 12px;
        font-weight: bold;
        line-height: 0.44;
        text-transform: uppercase;
        text-decoration: underline;
      }
      .loyalty__panel__offers__offer-code-wrap {
        @media #{$medium-up} {
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.15em;
          margin: 6px 0;
          .loyalty__panel__offers__offer-code {
            font-weight: bold;
          }
        }
      }
    }
    &-apply,
    &-remove {
      padding: 15px 30px;
      background: $color-black;
      color: $color-white;
      font-size: 15px;
      font-weight: bold;
    }
    &-remove {
      display: none;
    }
    &-selected {
      display: none;
      background: $color-shade-grey;
      color: $color-white;
      pointer-events: none;
    }
    &-apply {
      height: 33px;
      line-height: 2.8;
    }
  }
  &-count {
    @include swap_direction(padding-bottom, 15px);
    text-align: center;
  }
}

#viewcart {
  .loyalty_points_checkout_offers {
    text-align: center;
  }
}

#signin {
  #main {
    .signin_container {
      .sign-in {
        #signin-block {
          .inner-block {
            #new-account {
              .login-email {
                form#registration_short {
                  .form-item.email_promotions.phone2.container {
                    span {
                      width: 45%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#landing.is-loyalty-member {
  .account_landing_wrapper {
    .account.breadcrumb.panel {
      padding-bottom: 1150px;
    }
    .loyalty__panel__points__button {
      a.view-all {
        margin-top: 65px;
      }
    }
    #loyalty__panel__offers__earnpoints {
      top: 810px;
    }
    .best-sellers-loyalty-wrapper {
      .prod-shelf-row {
        .btn-shopnow {
          font-size: 1em;
          padding: 4px 10px 3px;
          text-transform: capitalize;
          width: auto;
        }
      }
    }
  }
}

.loyalty__panel__offers__list-container {
  .loyalty__panel__offers__offer--used {
    .loyalty__panel__offers__button {
      display: block !important;
      background: $color-shade-grey;
    }
  }
}

// Loyalty popup
.loyalty_popover__checkbox-container {
  display: none;
}

.loyalty_popover__promo {
  display: none;
}

.email_promotions {
  .loyalty_form_container {
    .loyalty-agrements {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
}

@media #{$small-only} {
  #signin-block {
    .form-item.loyalty {
      display: inline-block;
      input {
        float: left;
      }
      .loyalty_terms_condition {
        width: 90%;
        margin-left: 9%;
      }
    }
  }
}

.loyalty_mrkt_ldng {
  #loyalty_market_sticky_nav {
    .loyality_sign_off {
      padding: 20px 0 0 8px;
    }
    .loyalty_join_now,
    .loyalty_signin {
      button.btn_sign_in,
      button.btn_join {
        @media #{$small-only} {
          padding-left: 20px;
          padding-right: 20px;
          width: 100%;
        }
      }
      a.btn_join {
        display: block;
        margin: 15px 0 0;
        font-size: 18px;
        background: #000;
        font-weight: 400;
        border: 0;
        padding: 14px 50px;
        color: #fff;
        font-weight: bold;
        @media #{$small-only} {
          padding: 14px 0;
          width: 100%;
          text-align: center;
        }
      }
      @media #{$small-only} {
        padding: 0;
        width: 50%;
      }
    }
  }
}

.loyalty-grid {
  &__tier1 {
    .loyalty-grid__checkbox {
      background-color: $color-tier1;
    }
  }
  &__tier2 {
    .loyalty-grid__checkbox {
      background-color: $color-tier2;
    }
  }
  &__tier3 {
    .loyalty-grid__checkbox {
      background-color: $color-tier3;
    }
  }
}

.loyalty-grid-formatter__legend--mobile {
  @media #{$medium-up} {
    display: none;
  }
}

#loyalty__panel__offers {
  .loyalty__panel__offers__list {
    .loyalty__panel__offers__offer-data-container {
      min-height: 80px !important;
    }
  }
}

.actions.confirmation-panel__actions {
  margin-left: 0 !important;
}

.main_container {
  .loyalty__panel__offers__sku__list-container {
    .offer_sku_flexslider {
      ol.flex-control-paging {
        line-height: 1 !important;
        @media #{$medium-up} {
          max-width: 80%;
          margin-left: 10%;
        }
      }
    }
  }
}

// Points History
#loyalty__page__history {
  .account-loyalty {
    .tier-point-msg.next-anniversary-msg {
      margin-bottom: 30px;
    }
  }
}

.loyalty__panel__transactions__list__column-activity {
  line-height: 1.7rem;
}

// Buttons
.loyalty__panel__offers__remove.button.button--offers {
  text-transform: uppercase !important;
}
.loyalty__panel__offers__button.button.button--offers {
  display: block;
}

// Order confirmation - loyalty_join
.checkout-panel__content.loyalty-join-content {
  input[type='checkbox'] {
    vertical-align: middle;
    height: 15px !important;
    width: 15px;
    margin-right: 10px;
  }
  input[type='submit'] {
    display: block;
    margin-top: 0.3em;
  }
}

// Guest - join button
#confirmation-panel {
  .form-submit {
    margin-left: 0 !important;
  }
}

// Online - join checkbox
.checkout_loyalty-enrollment {
  width: 90%;
  display: flex;
  align-items: baseline;
  .checkout_loyalty-enrollment_checkbox {
    width: 10%;
    @media #{$medium-up} {
      width: 5%;
    }
  }
  .checkout_loyalty-enrollment_join_loyalty_msg {
    width: 85%;
  }
}

.loyalty-offer-overlay {
  #cboxContent {
    #cboxClose {
      background-size: 17.4px;
      width: 40px;
      height: 40px;
      border: none;
      background-color: transparent;
      box-shadow: none;
      outline: none;
      @if $new-loyalty-popup == true {
        background-size: 17.4px;
        width: 40px;
        height: 40px;
        border: none;
        background-color: transparent;
        box-shadow: none;
        outline: none;
      }
    }
  }
}
